@font-face {
  font-family: JetBrain;
  src: url(../fonts/jetbrain/JetBrainsMono.ttf);
}

@font-face {
  font-family: SFUIRounded;
  src: url(../fonts/ui-rounded/SFUIRounded.ttf);
}



body {
  font-family: 'SF Pro Display', sans-serif !important;
}

.fm-jetbrain {
  font-family: 'JetBrain', sans-serif  !important;
}

.fm-sfuirounded {
  font-family: 'SFUIRounded', sans-serif  !important;
}

.sa {
  width: 140px;
  height: 140px;
  padding: 26px;
  background-color: var(--kt-card-bg);
}
.sa-success {
  border-radius: 50%;
  border: 4px solid #50cd89;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  background-color: var(--kt-card-bg);
  width: 80px;
}
.sa-success:after, .sa-success:before {
  background: var(--kt-card-bg);
  content: '';
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}
.sa-success:before {
  border-radius: 40px 0 0 40px;
  width: 26px;
  height: 80px;
  top: -17px;
  left: 5px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}
.sa-success:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
  animation: rotatePlaceholder 4.25s ease-in;
  animation-delay: .25s;
}
.sa-success-placeholder {
  border-radius: 50%;
  border: 4px solid #50cd8849;
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.sa-success-fix {
  background-color: var(--kt-card-bg);
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.sa-success-tip, .sa-success-long {
  background-color: #50cd89;
  border-radius: 2px;
  height: 5px;
  position: absolute;
  z-index: 2;
}
.sa-success-tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 25px;
  animation: animateSuccessTip .75s;
  animation-delay: .25s;
}
.sa-success-long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
  animation: animateSuccessLong .75s;
  animation-delay: .25s;
}
  @keyframes animateSuccessTip {
    0%, 54% {
      width: 0;
      left: 1px;
      top: 19px;
   }
    70% {
      width: 50px;
      left: -8px;
      top: 37px;
   }
    84% {
      width: 17px;
      left: 21px;
      top: 48px;
   }
    100% {
      width: 25px;
      left: 14px;
      top: 45px;
   }
 }
  @keyframes animateSuccessLong {
    0%, 65% {
      width: 0;
      right: 46px;
      top: 54px;
   }
    84% {
      width: 55px;
      right: 0;
      top: 35px;
   }
    100% {
      width: 47px;
      right: 8px;
      top: 38px;
   }
 }
  @keyframes rotatePlaceholder {
    0%, 5% {
      transform: rotate(-45deg);
   }
    100%, 12% {
      transform: rotate(-405deg);
   }
 }

 .swal2-close:focus {
    box-shadow: none !important;
 }

  .swal2-popup .swal2-html-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .swal2-title {
    padding: 1.5em 1em 0 !important;
  }
  
  .swal2-icon {
    margin-top: 3em !important;
  }

  .swal2-popup {
    border-radius: 12px !important;
  }
  
  .swal2-popup .swal2-actions {
    margin: 0.25rem auto 1.75rem auto !important;
}

.rounded-modal {
  border-radius: 12px !important;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-16px {
  border-radius: 16px;
}

.rounded-16px-top {
  border-radius: 16px 16px 0 0;
}

.w-checkbox {
 width: 22.75px;
}

.rotate-90 {
  rotate: 90deg !important;
}

.rotate-90-n {
  rotate: -90deg !important;
}

.rotate-180 {
  rotate: 180deg !important;
}

.rotate-180-animate {
  transition: transform 0.5s ease; /* Add a smooth transition effect */
  transform: rotate(180deg);
}

.rotate-0-animate {
  transition: transform 0.5s ease; /* Add a smooth transition effect */
  transform: rotate(0);
}

.stepper-carousel .carousel-inner {
  overflow: visible;
}

.shine {
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 30%, #ffffff5e 45.52%, rgba(255, 255, 255, 0) 80%);
  background-repeat: no-repeat;
  animation: bg-move linear 4s infinite;
 }

 @-webkit-keyframes bg-move {
  0%   { background-position: -400px 0; }
  100% { background-position: 600px 0; }
}
@keyframes bg-move {
  0%   { background-position: -400px 0; }
  100% { background-position: 700px 0; }
}

.shine-sm {
  background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0) 30%, #ffffff5e 45.52%, rgba(255, 255, 255, 0) 80%);
  background-repeat: no-repeat;
  animation: bg-move-sm linear 3s infinite;
 }

 @-webkit-keyframes bg-move-sm {
  0%   { background-position: -100px 0; }
  100% { background-position: 150px 0; }
}
@keyframes bg-move-sm {
  0%   { background-position: -100px 0; }
  100% { background-position: 150px 0; }
}

.overlay-shine {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%, #00eeff6c 45%, rgba(255, 255, 255, 0) 80%);
  background-repeat: no-repeat;
  animation: bg-move-2 linear 5s infinite;
 }

 @-webkit-keyframes bg-move-2 {
  0%   { background-position: -400px 0; }
  100% { background-position: 1200px 0; }
}
@keyframes bg-move-2 {
  0%   { background-position: -400px 0; }
  100% { background-position: 1200px 0; }
}

.select-with-images {
  position: relative;
  width: 100%;
}

.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-option {
  display: flex;
  align-items: center;
}

.select-option img {
  margin-right: 10px;
  max-width: 20px;
  max-height: 20px;
}

.select-no-options {
  padding: 10px;
  text-align: center;
}

.select-no-options::after {
  content: " 😞";
}

.select-dropdown-with-image {
  position: absolute;
    width: inherit;
    background-color: var(--kt-card-bg);
    z-index: 1;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

input[type=number] { 
    -moz-appearance: textfield; 
}

@keyframes changeHue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.change-hue-animation {
  animation: changeHue 1s linear infinite;
}

.loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.column_1 {
  width: 5px;
  height: 10px;
  border-radius: 3px;
  background-color: #009ef7;
  animation: ima 1000ms infinite;
}

.column_2 {
  width: 5px;
  height: 15px; /* Adjust the height value as per your preference */
  margin-left: 7px;
  background-color: #0082ce;
  border-radius: 3px;
  animation: ima 1000ms infinite;
  animation-delay: 100ms;
}

.column_3 {
  width: 5px;
  height: 20px; /* Adjust the height value as per your preference */
  margin-left: 7px;
  background-color: #00639c;
  border-radius: 3px;
  animation: ima 1000ms infinite;
  animation-delay: 200ms;
}

.column_4 {
  width: 5px;
  height: 25px; /* Adjust the height value as per your preference */
  margin-left: 7px;
  background-color: #004a75;
  border-radius: 3px;
  animation: ima 1000ms infinite;
  animation-delay: 300ms;
}

@keyframes ima {
  30% {
    height: 40px; /* Adjust the height value as per your preference */
  }
  60% {
    height: 10px; /* Adjust the height value as per your preference */
  }
}

.doughnut { 
  background-color: transparent;
    width: 195px;
    height: 195px;
    border: var(--kt-light) 35px solid;
    border-radius: 50%;
}

.chart-series-labels {
  height: 12px;
  width: 12px;
  left: 0px;
  top: 0px;
  border-radius: 12px;
}

.border-red {
  border: solid 1px #f1416c;
}

.filter-submenu {z-index: 105;
  position: fixed;
  inset: auto 0px 0px auto;
  margin: 0px; transform:
  translate3d(-140px, -311px, 0px)}

  .wallet-webapp .wallet-header {
    /* position: sticky;
    top: 0; */
    background-color:var(--kt-card-bg) !important;
    z-index: 2;
    padding-top: 2rem;
  }

  .wallet-webapp .wallet-tabs {
    /* position: sticky !important;
    top: 250px; */
    background-color:var(--kt-card-bg) !important;
    z-index: 3;
  }

  .wallet-webapp .searchBox-section1 {
    /* position: sticky !important;
    top: 308px; */
    background-color:var(--kt-card-bg) !important;
    z-index: 3;
  }

  .bg-webapp {
    background-color: var(--kt-card-bg) !important;
  }

  .telegramWidget {
    opacity: 0.01;
    position: absolute;
    cursor: pointer;
    width: 100%;
    max-width: 230px;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
  }

  .telegram-button {
    pointer-events: none;
    position: relative;
    z-index: 2;
  }
  
  input[type="range"] {
    -webkit-appearance: none; 
    width: 100%; 
    height: 15px; 
    background-color:var(--kt-input-solid-bg);
    border-radius: 5px; 
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; 
    width: 23px; 
    height: 23px;
    background-color: #fff;
    border: 1px solid #ddddddbd;
    box-shadow: 0 0 0.3rem 0.1rem rgba(0, 0, 0, 0.05) !important;
    border-radius: 50%;
    cursor: pointer; 
  }
  
  #range-input::-webkit-slider-thumb::before {
    content: "50";
    position: absolute;
    top: -30px;
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
  }

  .bot-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: center repeat;
    background-size: 420px auto;
   }

  .form-check-custom.form-check-solid .form-check-input:not(:checked) {
    background-color: #e4e6ee !important;
  }

  .Toastify__toast{
    min-height: 48px !important;
  }
  @keyframes fadeDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Applying the animation when the div is shown */
  .fade-down {
    animation: fadeDown 0.4s ease-in-out;
  }

  #kt_aside_menu_wrapper {
    overflow-y: scroll; /* This will make the vertical scrollbar always visible */
  }

  /* Optionally, you can customize the appearance of the scrollbar */
  #kt_aside_menu_wrapper::-webkit-scrollbar {
    width: 10px; /* Adjust the width as needed */
  }

  #kt_aside_menu_wrapper::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 4px;
  }

  #kt_aside_menu_wrapper::-webkit-scrollbar-track {
    background-color: transparent; /* Scrollbar track color */
    border-radius: 4px;
  }

  .fixed-footer {
    align-items: center;
    background-color: #2738e0;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    width: calc(100% - 20px); /* Adjust width to account for the added margin */
    box-sizing: border-box;
    z-index: 1000;
    left: 10px;
    padding: 16px;
    border-radius: 10px;
  }
  
  .banner-text {
    outline: 0;
    color: #c1bdbd;
    text-align: left;
    font-size: 13px;
    padding: 0;
    margin: 0;
  }
  
  .btn-link {
    background-color: transparent !important;
    border: none !important;
    color: var(--kt-primary);
  }

  .app-engage {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 5;
  }

  .telegram-widget-support {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 500;
  }
  
  .app-engage .app-engage-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 8px;
    border-radius: 6px;
  }

  .disable {
    opacity: 0.5;
    pointer-events: none;
  }

  .filter-grayscale {
    filter: grayscale(1);
  }

  .multicolor-gradient {
    background: rgb(21,21,21);
    background: linear-gradient(45deg, rgba(21,21,21,1) 21%, #2d3a7b 50%, #ff22d6 86%);
    position: relative
}
  
/* Base styles for the title */
.large-title {
  font-size: 5.5rem;
  letter-spacing: -0.17rem;
  line-height: 6.5rem;
}


.rounded-20px {
  border-radius: 20px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 0.5s infinite;
}

.overflow-hidden-show {
  overflow: auto;
}
.overflow-hidden-show::-webkit-scrollbar-thumb {
      background-color: transparent !important; 
}

.opacity-80 {
  opacity: 0.8;
}

.w-130px {
  width: 130px;
}

.swap-input {
  border: none;
  outline: none;
  background-color: transparent;
}

.swap-input::placeholder {
  color: #dadada; /* Set the color you want for the placeholder text */
}

.swap-input-width {
  max-width: 160px;
}

/* Media query for smaller screens */
@media (max-width: 1440px) {
  .large-title {
    font-size: 4.5rem; 
    letter-spacing: -0.1rem;
  line-height: 5.5rem;
  }

  .swap-input-width {
    max-width: 130px;
  }
}

/* webapp wallet styles */



.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fs-45 {
  font-size: 2.8rem !important;
}

.letter-spacing-n {
  letter-spacing: -0.12rem !important;
}

.letter-spacing-n-1 {
  letter-spacing: -0.07rem !important;
}

.mb-n {
  margin-bottom: -0.35rem
}

.gap-8px {
  gap: 8px
}

.lh-normal {
  line-height: normal !important;
}

/* Define a custom class for the sheet-like modal */

.custom-modal-rounded .modal-content {
  border-radius: 12px !important;
}

.sheet-modal {
  border-radius: 18px 18px 0 0 !important;
}

.sheet-modal .modal-content {
  border-radius: 18px 18px 0 0 !important;
}

.sheet-height-60 .modal-content {
  min-height: 60vh;
}


.sheet-modal .modal-dialog {
  margin: 0;
  min-height: 100vh; /* Ensure a minimum height of viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.sheet-modal .modal-header {
  border-bottom: none !important;
  border-radius: 18px 18px 0 0 !important;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.sheet-modal .modal-body {
  flex-grow: 1; /* Expand to fill the remaining space */
  overflow-y: auto;
  padding: 0;
}

.gray-modal .modal-body {
  background-color: var(--kt-gray-200);
}

.gray-modal .modal-header {
  background-color: var(--kt-gray-200);
}

.sheet-modal .scrollable-content {
  padding: 15px;
}

.sheet-modal .modal-header .modal-title {
  text-align: center; /* Center align the title text */
  margin: 0 auto; /* Center horizontally */
  width: calc(100% - 36px); /* Adjust for the width of the close button (assuming default button size) */
}

.right-0 {
  position: absolute;
  right: 0;
}

.rounded-12px {
  border-radius: 12px;
}

.bg-webapp-white {
  background-color: var(--kt-card-bg);
}

/* Custom CSS for fade-up animation on modals with sheet-modal class */
.fadeup-modal.modal {
  transition: opacity 0.3s ease;
}

.fadeup-modal.modal.fade .modal-dialog {
  transform: translateY(50px);
  transition: transform 0.3s ease;
}

.fadeup-modal.modal.fade.show {
  opacity: 1;
}

.fadeup-modal.modal.fade.show .modal-dialog {
  transform: translateY(0);
}

.gray-scale-1 {
  filter: grayscale(1)
}


.webapp-custom-input {
  border: none;
  outline: none;
  background-color: transparent;
}

.w-115px {
  width: 115px;
}

.h-115px {
  height: 115px;
}

.webapp-swap-input::placeholder {
  color: var(--kt-gray-300);
}


.tel-alert {
  position: fixed !important;
  width: -webkit-fill-available;
  bottom: 0;
  z-index: 100000;
  backdrop-filter: blur(4px);
  background-color: #000000bf !important;
}


/* Animation */

@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

.animated {
  animation-duration: .3s;
  animation-fill-mode: both;
  -webkit-animation-duration: .3s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.infinite-rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-loading {
  animation: logoloading 1.5s ease-in-out infinite;
}

@keyframes logoloading  {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0.1;
  }
}

.splash-screen img {
  height: 30px !important;
}

.shadow-hover {
  box-shadow: 0 0 9px rgba(0, 0, 0, 0);
    transition: box-shadow 0.5s ease-in-out;
}

.shadow-hover:hover {
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);
}

.messenger-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: center repeat;
  background-size: 420px auto;
 }

 .tgme_background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.tgme_background_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
  background: var(--body-bg);
}

.tgm-bg {
  position: relative;
  background: rgb(213,216,141) !important;
  /* background: linear-gradient(243deg, rgba(213,216,141,1) 30%, rgba(136,184,132,1) 90%) !important; */
  background: url('../../../../public/media/custom/chat-bg.png'), linear-gradient(243deg,  var(--kt-tgmstart)  30%,  var(--kt-tgmend)  90%) !important;
  background-size: 100% !important;
  background-repeat: repeat-y !important;

}

.badge-blur {
  background-color: #00000040 !important;
  backdrop-filter: blur(5px);
}

.badge-blur-light {
  background-color: #1c1c1c34 !important;
  backdrop-filter: blur(20px);
}

.bg-message-skeleton {
 background-color: #1c1c1c34;
}

.backdrop-filter-15px {
  backdrop-filter: blur(15px);
}

.scroll-hidden::-webkit-scrollbar {
    display: none;
}

.oxa-drawer {
  position: fixed;
  top: 0;
  right: -500px;
  width: 100%;
  max-width: 500px;
  height: 100%;
  background-color: #f0f0f0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s linear;
  z-index: 10000000000;
}

.oxa-drawer.show {
  right: 0 !important;
}

.oxa-backdrop{
  background-color: #0000003a;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
}

/* Fade In Animation */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.backdrop-fade-in {
  background-color: #0000001e;
  animation: fadeIn 0.3s ease-in-out; /* Adjust duration and timing function as needed */
}

/* Fade Out Animation */
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.backdrop-fade-out {
  background-color: #000;
  animation: fadeOut 0.3s ease-in-out; /* Adjust duration and timing function as needed */
}

.read-all {
  position: fixed;
  bottom: 75px;
  right: 10px;
  border-radius: 50%;
}

.unread-counts{
  position: absolute;
top: -8px;
border-radius: 10px;
padding: 0px 6px;
}

.nowrap {
  white-space: nowrap;
}

.disable-opacity {
  opacity: var(--bs-btn-disabled-opacity);
}

.flex-wrap-overflow {
  overflow-wrap: anywhere !important;
}

.border-none {
  border: none !important;
}

.backicon {
  transform: translateX(0);
  transition: transform .3s;
  cursor: pointer;
 }

 .backicon:hover {
  transform: translateX(-4px);
  color: #019EF7 !important;
 }

 .addressbook-container:hover .address {
  color: var(--kt-primary) !important;
}

.addressbook-hover-card:hover {
  background-color: var(--kt-gray-200);
}

.wrap-break-word {
  word-wrap:break-word;
}


